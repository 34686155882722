import { computed, onMounted, reactive, ref, Ref, toRaw, toRefs } from "vue";
import moment from "moment";
import { useRoute } from "vue-router";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import useMock from "@/utils/payment/useMock";
import useStripedRowClass from "@/utils/payment/useStripedRowClass";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import useThousands from "@/utils/payment/useThousands";
import Data from "@/views/Payment/BU/DealerSetting/mockData";
import downloadFile from "@/utils/rv/downloadFile";
import { DataProps, ItemProps } from "@/views/Payment/BU/DealerSetting/type";
import {
  getDealerPayoutCondition,
  getRoundListByDate,
  fetchListDealerSetting,
  fetchListDealerSettingSIPayout
} from "@/API/payment";
const useDealerSetting = () => {
  const entityRef = ref()
  // 表头
  const column = ref([
    {
      title: "Dealer Code",
      dataIndex: "dealerCode",
    },
    {
      title: "Dealer Name",
      dataIndex: "dealerNameCN",
    },
    {
      title: "Dealer Group",
      dataIndex: "dealerGroup",
    },
    {
      title: "Dealer Region",
      dataIndex: "region",
    },
    {
      title: "Payout Amount w/o VAT",
      dataIndex: "amountWOVat",
      width: 200,
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
    },
    {
      title: "Payout Amount w VAT",
      dataIndex: "amountWVat",
      width: 200,
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
    },
  ]);
  // 查询条件
  const query = reactive({
    year: "",
    payoutRound: "",
    entity: "",
    dealerName: "",
    dealerCode: "",
    region: "",
    dealerGroup: "",
  });
  // 查询条件的下拉列表
  const dealerCodeRes = ref();
  const dealerNameRes = ref();
  const dealerRegionRes = ref();
  const dealerGroupRes = ref();
  const dealerCodeList = computed(() => {
    return dealerCodeRes.value ? dealerCodeRes.value : [];
  });
  const dealerNameList = computed(() => {
    return dealerNameRes.value ? dealerNameRes.value : [];
  });
  const dealerRegionList = computed(() => {
    return dealerRegionRes.value ? dealerRegionRes.value : [];
  });
  const dealerGroupList = computed(() => {
    return dealerGroupRes.value ? dealerGroupRes.value : [];
  });
  // 获取折叠面板表格数据
  const res = ref();
  const NormalDelearData = computed(() => {
    return res.value?.normalDealer;
    // return res.value?.data?.data?.normalDealer;
  });
  const RiskDelearData = computed(() => {
    // return res.value?.data?.data?.riskDealer;
    return res.value?.riskDealer;
  });
  // 查询round列表
  const roundRes = ref();
  const roundList = computed(() => {
    return roundRes.value ? roundRes.value : [];
  });
  const searchRound = () => {
    roundRes.value = null;
    query.payoutRound = "";
    return getRoundListByDate({
      params: {
        month: 0,
        year: moment(query.year).format("YYYY"),
        type: "DEALER",
      },
    }).then((res) => {
      roundRes.value = res;
      query.payoutRound = res.length ? res[0] : undefined;
    });
  };
  const activeKey = ref<string[]>([]);
  const search = () => {
    const _query = toRaw(query);
    _query.year = moment(query.year).format("YYYY");
    fetchListDealerSettingSIPayout(toRaw(_query)).then((data) => {
      res.value = data;
      activeKey.value = []
      if (typeof data.normalDealer === 'object' && Object.keys(data.normalDealer).length) {
        activeKey.value.push('2')
      }
      if (typeof data.riskDealer === 'object' && Object.keys(data.riskDealer).length) {
        activeKey.value.push('1')
      }
    });
    // useMock<DataProps>(Data, query).then((data) => {
    //   console.log(data);
    //   res.value = data;
    // });
  };
  const initCondition = () => {
    return new Promise((resolve) => {
      getDealerPayoutCondition().then((res) => {
        query.year = res.year.toString();
        resolve(res);
      });
    });
  };
  onMounted(() => {
    console.log("onMounted");
    initCondition().then(() => {
      return searchRound();
    }).then(() => {
      search();
    });
  });
  const reset = () => {
    roundRes.value = null;
    query.payoutRound = "";
    entityRef.value.reset()
    initCondition().then(() => {
      return searchRound();
    }).then(() => {
      search();
    });
  };
  // 操作按钮
  const handleDownload = () => {
    const params = {
      url: "/ptapi/vendor/export",
      method: "post",
      params: {},
      fileName: "Vendor Code List.xlsx",
    };
    downloadFile(params);
  };
  const uploadVisible = ref<boolean>(false);
  const normalUploadVisible = ref<boolean>(false);
  const nameUploadVisible = ref<boolean>(false);
  const uploadTitle = ref<string>();
  const handleNormalDrawerOpen = () => {
    normalUploadVisible.value = true;
    uploadTitle.value = "Normal Dealer";
  };
  const handleRiskDrawerOpen = () => {
    uploadVisible.value = true;
    uploadTitle.value = "Risk Dealer";
  };
  // 斑马纹
  const getRowClass = useStripedRowClass<DataProps>();
  return {
    search,
    reset,
    searchRound,
    query,
    column,
    NormalDelearData,
    RiskDelearData,
    roundList,
    roundRes,
    dealerCodeList,
    dealerNameList,
    dealerRegionList,
    dealerGroupList,
    handleDownload,
    uploadVisible,
    normalUploadVisible,
    nameUploadVisible,
    uploadTitle,
    handleNormalDrawerOpen,
    handleRiskDrawerOpen,
    getRowClass,
    entityRef,
    activeKey
  };
};
export default useDealerSetting;
