
import { computed, defineComponent, PropType, ref } from "vue";
import { RightOutlined } from "@ant-design/icons-vue";
import useDealerSetting from "@/hooks/payment/bu/useDealerSetting";
import useStripedRowClass from "@/utils/payment/useStripedRowClass";
import { ItemProps } from "./type";
type PropsDataType = { [key: string]: ItemProps[] };
export default defineComponent({
  name: "App",
  props: {
    data: {
      type: Object as PropType<PropsDataType>,
      defalut: [],
    },
    column: {
      type: Array,
    },
    type: {
      type: String,
      default: "risk",
    },
  },
  setup(props) {
    const activeKey = ref("0");
    // 斑马纹
    const getRowClass = useStripedRowClass<ItemProps>();
    const _data = computed(() => {
      const data = props.data ? props.data : [];
      const arr = [];
      for (const key in data) {
        if (key !== "NORMAL") {
          arr.push({
            name: key,
            data: data[key],
          });
        }
      }
      console.log(props.type, arr);
      return arr;
    });
    return {
      getRowClass,
      activeKey,
      _data,
    };
  },
  components: {
    RightOutlined,
  },
});
