
import { defineComponent, ref, computed } from "vue";
import { RightOutlined } from "@ant-design/icons-vue";
import downloadFile from "@/utils/payment/downloadFile";
import YearPicker from "../../components/YearPicker.vue";
import collapseTable from "./collapseTable.vue";
import useDealerSetting from "@/hooks/payment/bu/useDealerSetting";
import uploadFile from "./uploadFile.vue";
import NormalUploadFile from "./normalUploadFile.vue";
import NameUploadFile from "./nameUploadFile.vue";
import { getDealerNewRound } from "@/API/payment";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
export default defineComponent({
  name: "DealerSetting",
  props: {},
  setup() {
    const {
      search,
      reset,
      searchRound,
      query,
      NormalDelearData,
      RiskDelearData,
      column,
      roundList,
      roundRes,
      dealerCodeList,
      dealerNameList,
      dealerRegionList,
      dealerGroupList,
      handleDownload,
      handleNormalDrawerOpen,
      handleRiskDrawerOpen,
      // handleNameDrawerOpen,
      uploadVisible,
      normalUploadVisible,
      nameUploadVisible,
      uploadTitle,
      getRowClass,
      entityRef,
      activeKey,
    } = useDealerSetting();
    const handleDatachange = () => {
      search();
    };
    const handleDownloadClick = () => {
      const params = {
        url: `/ptapi/dealer/setting/downloadRiskDealerExcel`,
        method: "post",
        // fileName: `${+new Date()}.xlsx`,
        params: query,
      };
      downloadFile(params);
    };
    // 查询当前最新payout round
    // 填充payout round
    const res = ref();
    const payoutRound = computed(() => {
      return res.value ? res.value.payoutRound : "";
    });
    getDealerNewRound().then((_res) => {
      res.value = _res;
    });
    const nameUpload = ref();
    const handleNameDrawerOpen = () => {
      nameUploadVisible.value = true;
      uploadTitle.value = "Name/Code Changing";
      nameUpload.value.setQuery(query, roundRes);
    };
    // getRoundListByDate({
    //   params: {
    //     year: "0",
    //     month: "0",
    //     type: "DEALER",
    //   },
    // }).then((_res) => {
    //   res.value = _res;
    // });
    return {
      open,
      search,
      reset,
      searchRound,
      query,
      activeKey,
      roundList,
      dealerCodeList,
      dealerNameList,
      dealerRegionList,
      dealerGroupList,
      handleDownload,
      handleNormalDrawerOpen,
      handleRiskDrawerOpen,
      handleNameDrawerOpen,
      nameUpload,
      handleDatachange,
      uploadVisible,
      normalUploadVisible,
      nameUploadVisible,
      uploadTitle,
      NormalDelearData,
      RiskDelearData,
      column,
      handleDownloadClick,
      payoutRound,
      getRowClass,
      entityRef,
    };
  },
  components: {
    RightOutlined,
    collapseTable,
    YearPicker,
    uploadFile,
    NormalUploadFile,
    NameUploadFile,
    EntitySelector,
  },
});
