
import { computed, defineComponent, PropType, reactive, ref, watch, unref } from "vue";
import moment from "moment";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import useModel from "@/hooks/useModel";
import downloadFile from "@/utils/claim/downloadFile";
import { FileItem } from "@/API/types";
import YearPicker from "../../components/YearPicker.vue";
import { getRoundListByDate, getDealerPayoutCondition } from "@/API/payment";
import axios from "axios";
import { useStore } from 'vuex'

type titleProps = "Risk Dealer" | "Normal Dealer" | "Name/Code Changing";
type StatusType = "start" | "beforeUpload" | "checked" | "imported";
export default defineComponent({
  name: "App",
  props: {
    title: {
      type: String as PropType<titleProps>,
      default: "Risk Dealer",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { state } = useStore()

    const _title = computed(() => {
      return `Upload ${props.title} Name List`;
    });
    const _visible = useModel(props, "visible");
    const handleCancel = () => {
      _visible.value = false;
    };
    // entity 列表
    const entityList = computed(() => {
      return ["BBAC", "FBAC", "MBCL", "DENZA"];
    });
    // 查询条件
    const query = reactive({
      year: '',
      payoutRound: "",
    });
    // watch(() => props.query, (val) => {
    //   if (val && val.year) {
    //     query.year = moment(val.year).format("YYYY")
    //   }
    // })
    const roundRes = ref();
    const roundList = computed(() => {
      return roundRes.value ? roundRes.value : [];
    });
    const searchRound = () => {
      roundRes.value = null;
      query.payoutRound = "";
      getRoundListByDate({
        params: {
          month: 0,
          year: moment(query.year).format("YYYY"),
          type: "DEALER",
        },
      }).then((res) => {
        roundRes.value = res;
        query.payoutRound = res.length ? res[0] : undefined
      });
    };
    // getDealerPayoutCondition().then((res) => {
    //     query.month =
    //       res.year + "-" + (res.month > 10 ? res.month : "0" + res.month);
    //     query.year = res.year.toString();
    //     searchRound()
    //   });
    const setQuery = (_query: any, _roundRes: any) => {
      query.year = moment(_query.year).format("YYYY")
      query.payoutRound = _query.payoutRound
      roundRes.value  = unref(_roundRes)
    }
    // 上传相关
    const uploadStatus = ref<StatusType>("start");
    const curUploadFile = ref<object>({});
    const uploadFileSize = ref<string>("");
    // formData
    const formData = new FormData();
    const curPercent = computed(() => {
      if (
        uploadStatus.value === "checked" ||
        uploadStatus.value === "imported"
      ) {
        return 100;
      } else {
        return 0;
      }
    });
    // 上传前校验
    const beforeUpload = (file: FileItem) => {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        message.error("Only xlsx format files can be uploaded!");
      }
      const isLt5M = file.size / 1024 / 1024 < state.payment.maxFileSize;
      if (!isLt5M) {
        message.error(`The file size should not be larger than ${state.payment.maxFileSize}MB!`);
      }
      return isXlsx && isLt5M;
    };
    // 保存文件数据
    const uploadRequest = (source: any) => {
      if (formData.has("file")) {
        formData.delete("file");
      }
      formData.append("file", source.file);
      curUploadFile.value = source.file;
      uploadFileSize.value =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      uploadStatus.value = "beforeUpload";
    };
    const params = {
      file: formData
    };
    const downloadModalVisible = ref<boolean>(false);
    const cancelTip = () => {
      downloadModalVisible.value = false;
    };
    const downloadTipsText = ref<string>("");
    const emptyVisible = ref<boolean>(false);
    const emptyTipsText = ref<string>("");
    const fileId = ref("");
    const uploadFile = (source: any) => {
      if (!query.payoutRound) {
        message.error("Please select payout round.");
        return;
      }
      axios({
        method: "post",
        data: formData,
        url: `/ptapi/dealer/setting/uploadNameCodeChangingFile?payoutRound=${query.payoutRound}`,
        params: params,
      }).then((res) => {
        if (res.data.code !== "0" && res.data.code !== "9011") {
          // 显示模态窗口
          downloadModalVisible.value = true;
          downloadTipsText.value = res.data.message;
          fileId.value = res.data.data;
          uploadStatus.value = "start";
        } else if (res.data.code === "9011") {
          emptyVisible.value = true;
          emptyTipsText.value = res.data.message;
          uploadStatus.value = "start";
        } else {
          message.success("File check successfully!");
          uploadStatus.value = "checked";
        }
      });
    };
    // 导入接口调用
    const importFile = () => {
      if (!query.payoutRound) {
        message.error("Please select payout round.");
        return;
      }
      const params = {
        file: formData
      };
      axios({
        method: "post",
        data: formData,
        url: `/ptapi/dealer/setting/importNameCodeChangingFile?payoutRound=${query.payoutRound}`,
        params: params,
      }).then((res) => {
        if (res.data.code !== "0") {
          Modal.warning({
            title: "Tips",
            content: res.data.message,
            centered: true,
          });
        } else {
          uploadStatus.value = "imported";
          message.success("File Upload successfully!");
          context.emit("change-table");
        }
      });
    };
    const handleBrowse = () => {
      uploadStatus.value = "start";
    };
    const handleDownload = () => {
      const params = {
        url: `/ptapi/dealer/setting/downloadNameCodeChangingTemplate`,
        method: "get",
        fileName: `${+new Date()}.xlsx`,
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    };
    // 上传功能出错后下载模态窗口
    const handleCheckedMsgDownload = () => {
      const downloadParams = {
        fileId: fileId.value,
      };
      const params = {
        url: `/ptapi/downloadFile`,
        method: "get",
        params: downloadParams,
        fileName: `${+new Date()}.xlsx`,
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8").then(
        (res) => {
          downloadModalVisible.value = false;
          emptyVisible.value = false;
        }
      );
    };
    return {
      _title,
      _visible,
      handleCancel,
      beforeUpload,
      uploadRequest,
      uploadFile,
      importFile,
      handleBrowse,
      handleDownload,
      handleCheckedMsgDownload,
      curUploadFile,
      uploadFileSize,
      curPercent,
      uploadStatus,
      downloadModalVisible,
      downloadTipsText,
      emptyVisible,
      emptyTipsText,
      cancelTip,
      query,
      entityList,
      setQuery,
      roundList,
      searchRound
    };
  },
  components: {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    YearPicker,
  },
});
