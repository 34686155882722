
import { computed, defineComponent, PropType, reactive, ref, watch } from "vue";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import useModel from "@/hooks/useModel";
import downloadFile from "@/utils/claim/downloadFile";
import { FileItem } from "@/API/types";
import axios from "axios";
import { useStore } from "vuex";

type titleProps = "Risk Dealer" | "Normal Dealer" | "Name/Code Changing";
type StatusType = "start" | "beforeUpload" | "checked" | "imported";
export default defineComponent({
  name: "App",
  props: {
    title: {
      type: String as PropType<titleProps>,
      default: "Risk Dealer",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    round: {
      type: String,
    },
  },
  setup(props, context) {
    const { state } = useStore();

    const _title = computed(() => {
      return `Upload ${props.title} Name List`;
    });
    const _visible = useModel(props, "visible");
    const handleCancel = () => {
      _visible.value = false;
    };
    // entity 列表
    const entityList = computed(() => {
      const arr = ["MBCL"];
      const _arr = state.user.entityList.map((item: any) => item.entityName);
      return arr.filter((item: any) => _arr.includes(item));
    });
    // 查询条件
    const query = reactive({
      entity: null,
      payoutRound: "",
    });
    watch(
      () => props.round,
      (val) => {
        query.payoutRound = val as string;
      }
    );
    // 上传相关
    const uploadStatus = ref<StatusType>("start");
    const curUploadFile = ref<object>({});
    const uploadFileSize = ref<string>("");
    // formData
    const formData = new FormData();
    const curPercent = computed(() => {
      if (
        uploadStatus.value === "checked" ||
        uploadStatus.value === "imported"
      ) {
        return 100;
      } else {
        return 0;
      }
    });
    // 上传前校验
    const beforeUpload = (file: FileItem) => {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        message.error("Only xlsx format files can be uploaded!");
      }
      const isLt5M = file.size / 1024 / 1024 < state.payment.maxFileSize;
      if (!isLt5M) {
        message.error(
          `The file size should not be larger than ${state.payment.maxFileSize}MB!`
        );
      }
      return isXlsx && isLt5M;
    };
    // 保存文件数据
    const uploadRequest = (source: any) => {
      if (formData.has("file")) {
        formData.delete("file");
      }
      formData.append("file", source.file);
      curUploadFile.value = source.file;
      uploadFileSize.value =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      uploadStatus.value = "beforeUpload";
    };
    const params = {
      file: formData,
    };
    const downloadModalVisible = ref<boolean>(false);
    const cancelTip = () => {
      downloadModalVisible.value = false;
    };
    const downloadTipsText = ref<string>("");
    const emptyVisible = ref<boolean>(false);
    const emptyTipsText = ref<string>("");
    const fileId = ref("");
    const uploadFile = (source: any) => {
      if (!query.entity) {
        message.error("Please select entity.");
        return;
      }
      axios({
        method: "post",
        data: formData,
        url: `/ptapi/dealer/setting/uploadNormalDealerFile?entity=${query.entity}&payoutRound=${query.payoutRound}`,
        params: params,
      }).then((res) => {
        if (
          res.data.code !== "0" &&
          res.data.code !== "9011" &&
          res.data.code !== "9012"
        ) {
          // 显示模态窗口
          downloadModalVisible.value = true;
          downloadTipsText.value = res.data.message;
          fileId.value = res.data.data;
          uploadStatus.value = "start";
        } else if (res.data.code === "9011" || res.data.code === "9012") {
          emptyVisible.value = true;
          emptyTipsText.value = res.data.message;
          uploadStatus.value = "start";
        } else {
          message.success("File check successfully!");
          uploadStatus.value = "checked";
        }
      });
    };
    // 导入接口调用
    const importFile = () => {
      const params = {
        file: formData,
      };
      axios({
        method: "post",
        data: formData,
        url: `/ptapi/dealer/setting/importNormalDealerFile?entity=${query.entity}&payoutRound=${query.payoutRound}`,
        params: params,
      }).then((res) => {
        if (res.data.code !== "0") {
          Modal.warning({
            title: "Tips",
            content: res.data.message,
            centered: true,
          });
        } else {
          uploadStatus.value = "imported";
          message.success("File Upload successfully!");
          context.emit("change-table");
        }
      });
    };
    const handleBrowse = () => {
      uploadStatus.value = "start";
    };
    const handleDownload = () => {
      if (!query.entity) {
        message.error("Please select entity.");
        return;
      }
      const params = {
        url: `/ptapi/dealer/setting/downloadNormalDealerTemplate`,
        method: "get",
        fileName: `${+new Date()}.xlsx`,
        params: {
          entity: query.entity,
        },
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    };
    // 上传功能出错后下载模态窗口
    const handleCheckedMsgDownload = () => {
      const downloadParams = {
        fileId: fileId.value,
      };
      const params = {
        url: `/ptapi/downloadFile`,
        method: "get",
        params: downloadParams,
        fileName: `${+new Date()}.xlsx`,
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8").then(
        (res) => {
          downloadModalVisible.value = false;
          emptyVisible.value = false;
        }
      );
    };
    return {
      _title,
      _visible,
      handleCancel,
      beforeUpload,
      uploadRequest,
      uploadFile,
      importFile,
      handleBrowse,
      handleDownload,
      handleCheckedMsgDownload,
      curUploadFile,
      uploadFileSize,
      curPercent,
      uploadStatus,
      downloadModalVisible,
      downloadTipsText,
      emptyVisible,
      emptyTipsText,
      cancelTip,
      query,
      entityList,
    };
  },
  components: {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
  },
});
